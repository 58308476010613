import ChannelService from "js/channelTalk";
import { useEffect } from "react";
import {useLocation} from "react-router";

function ChannelShowMessenger(){
    if(!window.ChannelIO){
        ChannelService.loadScript();
        ChannelService.boot({
            pluginKey: process.env.REACT_APP_CHANNELTALK_ID,
        });

        setTimeout(() => {
            ChannelService.showMessenger();
        }, 200);
    }else{
        ChannelService.showMessenger();
    }
}

function ChannelTalkLoad(){
    const location = useLocation();
    const pathname = location.pathname;

    useEffect(() => {
        if(!window.ChannelIO && (pathname === "/join/confirm/wait")){
            ChannelService.loadScript();
            ChannelService.boot({
                pluginKey: process.env.REACT_APP_CHANNELTALK_ID,
            });
        }else{
            //ChannelService.showChannelButton();
        }
    }, []);
  
    return null;
}

export {ChannelTalkLoad,ChannelShowMessenger};